import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import catHome from "../images/cat-home.jpg"
import catCommercial from "../images/cat-commercial.jpg"
import catAuto from "../images/cat-auto.jpg"

import "./index.css"
import "../components/style.css"
import "./services.css"

const ServicesPage = () => {
  const images = useStaticQuery(graphql`
    query {
      home: file(relativePath: { eq: "cat-home.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      commercial: file(relativePath: { eq: "cat-commercial.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      auto: file(relativePath: { eq: "cat-auto.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="שירותים" />
      <div className="services-wrapper">
        <div className="row categories">
          <div className="col-4 col-sm-4">
            <h3>ביתי</h3>
            <Img
              fluid={images.home.childImageSharp.fluid}
              alt="שירות מנעולן לבית"
            />
          </div>
          <div className="col-4 col-sm-4">
            <h3>מסחרי</h3>
            <Img
              fluid={images.commercial.childImageSharp.fluid}
              alt="שירות מנעולן מסחרי"
            />
          </div>
          <div className="col-4 col-sm-4">
            <h3>רכב</h3>
            <Img
              fluid={images.auto.childImageSharp.fluid}
              alt="שירות מנעולן רכב"
            />
          </div>
        </div>
        <div className="row services">
          <div className="col-sm-12">
            <h2>שירותי מנעולן מומחים</h2>
            <p>הגנו על הבית או העסק שלכם. בחרו במנעולן המקומי לשירות מעולה.</p>
            <div className="services-services-lists row">
              <div className="col-sm-6 col-lg">
                <h4>פריצה</h4>
                <ul className="list">
                  <li>פריצת דלתות נעולות</li>
                  <li>פריצת דלתות טרוקות</li>
                  <li>פריצת כספת ביתית</li>
                  <li>פריצת רכבים</li>
                  <li>פריצת מנעול תיבת דואר</li>
                  {/* <li>פריצה לבית</li>
                  <li>פריצה לבניין</li>
                  <li>פריצה לעסק</li>
                  <li>פריצה לרכב</li> */}
                </ul>
              </div>
              <div className="col-sm-6 col-lg">
                <h4>התקנה וכוונון</h4>
                <ul className="list">
                  <li>התקנת מנעול רב בריח</li>
                  <li>התקנת מנעול חוסם</li>
                  <li>התקנת מנעול פלדלת</li>
                  <li>כוונון צירי דלת</li>
                  <li>חילוץ מפתחות שבורים</li>
                </ul>
              </div>
              <div className="col-sm-6 col-lg">
                <h4>החלפה</h4>
                <ul className="list">
                  <li>החלפת מנעולים</li>
                  <li>החלפת צילינדרים</li>
                  <li>החלפת מנגנון דלת חוסם</li>
                  <li>החלפת מנגנון דלת עץ</li>
                  <li>החלפת צילינדר מוגן פריצה</li>
                </ul>
              </div>
              {/* <div className="col-sm-6 col-lg-3">
                <h4>ועוד</h4>
                <ul className="list">
                  <li>שירותים מסחרים</li>
                  <li>פריצת כספות</li>
                  <li>תיבות דואר</li>
                </ul>
              </div> */}
            </div>
          </div>
          <div className="ml-auto mr-auto">
            <p className="text-center">
              <div className="d-inline-block">מחפשים משהו שלא מופיע כאן?</div>{" "}
              <div className="d-inline-block">
                <Link to="/contact">צור קשר</Link> כדי לברר.
              </div>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ServicesPage
